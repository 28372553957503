::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  padding: 1rem;
  position: initial;
}
/* Styling the thumb */
::-webkit-scrollbar-thumb {
  background: #adadad;
  /* Default background color of the thumb */
  border-radius: 3px;
  opacity: 0.3;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}
/* Container with scrollbar */
.with-scrollbar {
  overflow-y: auto;
  max-height: 500px;
  padding-right: 15px;
  box-sizing: content-box;
  margin-right: 70px;
}
.center {
  align-items: center;
  text-align: center;
}
.full-height {
  height: 75vh;
}
.custom-select.is-invalid,
.form-control.is-invalid,
.custom-select:invalid,
.form-control:invalid,
.custom-select.is-valid,
.form-control.is-valid,
.custom-select:valid,
.form-control:valid {
  &:focus {
    box-shadow: none !important;
  }
}
.floatingLabel {
  opacity: 0.6;
}
.button:hover {
  background: #D7DF21;
}
.button:active {
  background: #D7DF21;
}
.button {
  background: #adadad;
  border-radius: 999px;
  box-shadow: #9c9c92 0 10px 20px -10px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji", EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 18px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
  /* width: 10rem;
  margin-top: auto; */
}
.bottom {
  position: absolute;
  bottom: 0;
}
.tab {
  border: none;
  border-bottom: 1px solid grey;
  background-color: transparent;
  cursor: pointer;
  color: grey;
  margin: 0;
}
.active {
  border: none;
  border-bottom: 1px solid #D7DF21;
  background-color: transparent;
  cursor: pointer;
  margin: 0;
  color: #D7DF21;
}
.grid {
  display: grid;
  grid-gap: 1rem;
}
.two-rows {
  grid-template-rows: auto auto;
}
.two-columns {
  grid-template-columns: 3fr 1fr;
}
.two-columns-equal {
  grid-template-columns: 1fr 1fr;
}
.no-word-break {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}
.background-image {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.background-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('/public/table.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: grayscale(100%);
  z-index: -1;
}
.background {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.border-container {
  border: 10px solid #D4DD26;
  padding: 10px;
  border-radius: 5px;
}
.container {
  position: relative;
  width: 80%;
  height: 85vh;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
  margin: auto;
  margin-top: 4rem;
  margin-bottom: 4rem;
  background-color: white;
}
.form-container {
  display: grid;
  grid-template-rows: auto 4fr;
  grid-gap: 1rem;
  justify-content: space-between;
  padding: 20px;
}
.form-content {
  margin-top: 0;
  /* overflow-y: auto; */
  max-height: 500px;
}
.form-sidebar {
  border-left: 1px solid #ccc;
  margin-bottom: 7rem;
  padding-left: 0.5rem;
  max-width: 1280px;
  /* max-height: 734.344px; */
  overflow: hidden;
  font-size: 0.9em;
}
.order-summary-item {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.5em;
}
.order-label {
  flex-shrink: 0;
  margin-right: 0.5em;
}
.order-values {
  display: flex;
  flex-wrap: wrap;
  list-style-type: disc;
  padding: auto;
  margin: 0;
}
.order-values li {
  margin-right: 0.5em;
}
.order-value {
  flex-shrink: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.button-container {
  margin-top: 1em;
  font-size: 1em;
  position: fixed;
  bottom: 6rem;
}
.form-navigation {
  display: flex;
  justify-content: space-between;
}
button {
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
}
.total-container {
  margin-top: auto;
  position: fixed;
  bottom: 6rem;
}
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.total {
  margin-bottom: 5rem;
}
.checkbox-wrapper-16 *,
.checkbox-wrapper-16 *:after,
.checkbox-wrapper-16 *:before {
  box-sizing: border-box;
}
.checkbox-wrapper-16 .checkbox-input {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.checkbox-wrapper-16 .checkbox-input:checked + .checkbox-tile {
  border-color: #D7DF21;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #D7DF21;
}
.checkbox-wrapper-16 .checkbox-input:checked + .checkbox-tile:before {
  transform: scale(1);
  opacity: 1;
  background-color: #D7DF21;
  border-color: #D7DF21;
}
.checkbox-wrapper-16 .checkbox-input:checked + .checkbox-tile .checkbox-icon,
.checkbox-wrapper-16 .checkbox-input:checked + .checkbox-tile .checkbox-label {
  color: #D7DF21;
}
.checkbox-wrapper-16 .checkbox-input:focus + .checkbox-tile:before {
  transform: scale(1);
  opacity: 1;
}
.checkbox-wrapper-16 .checkbox-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 192px;
  height: 192px;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
}
.checkbox-wrapper-16 .checkbox-tile:before {
  content: "";
  position: absolute;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  background-color: #fff;
  border-radius: 50%;
  top: 0.25rem;
  left: 0.25rem;
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.checkbox-wrapper-16 .checkbox-tile:hover {
  border-color: #D7DF21;
}
.checkbox-wrapper-16 .checkbox-tile:hover:before {
  transform: scale(1);
  opacity: 1;
}
.checkbox-wrapper-16 .checkbox-icon {
  transition: 0.375s ease;
  color: #494949;
}
.checkbox-wrapper-16 .checkbox-icon img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: center;
}
.checkbox-wrapper-16 .checkbox-label {
  color: #707070;
  transition: 0.375s ease;
  text-align: center;
}
.checkbox-description {
  font-size: 0.8em;
  color: #666;
}
.custom-select-container {
  position: relative;
  display: inline-block;
  width: 300px;
}
.custom-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}
.custom-select:hover {
  border-color: #888;
}
.custom-select-items {
  max-height: 500px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background-color: #fff;
  z-index: 1;
}
.custom-select-item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}
.custom-select-item:last-child {
  border-bottom: none;
}
.custom-select-item:hover {
  background-color: #f1f1f1;
}
.custom-select img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.custom-select-item.selected {
  background-color: #e0e0e0;
}
.option-description {
  font-size: 0.9em;
  color: #666;
  flex: 1;
}
.option-price {
  margin-left: 10px;
  color: #333;
  white-space: nowrap;
}
.side-by-side {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.side-by-side-item {
  margin: 0 10px;
}
@media (max-width: 768px) {
  .side-by-side {
    flex-direction: column;
  }
  .side-by-side-item {
    margin: 10px 0;
  }
}